/**
 * Benutzer
 * Eine API zum Anlegen, Aktualisieren, Anzeigen und Löschen von Benutzern in ADNOVA+ und im Portal. Bei internen Benutzern handelt es sich um Benutzer innerhalb einer Buchstelle oder Benutzer eines selbstbuchenden Landwirts/Unternehmens. Externe Benutzer sind  Benutzer eines Landwirts/Unternehmens, die von einer Buchstelle betreut werden. Hauptverantwortliche sind immer externe Benutzer, aber nicht jeder externe Benutzer ist Hauptverantwortlicher. Das Anlegen von externen Benutzern ist für interne Benutzer möglich. Nur ein externer Benutzer, der Hauptverantwortlicher ist, kann weitere externe Benutzer für die eigenen Betriebe anlegen. Interne Benutzer können externe Benutzer lesen und anlegen, sofern sie die entsprechenden Zugriffsrechte haben. Das Aktualisieren und Löschen von Benutzern kann hingegen nur von Hauptverantworåtlichen (externer Benutzer) in ihrer eigenen Organisation durchgeführt werden. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */


/**
 * Anrede eines Benutzers.
 */
export enum BenutzerAnredeDTO {
    Frau = 'Frau',
    FrauSteuerberaterin = 'Frau Steuerberaterin',
    Herrn = 'Herrn',
    HerrnSteuerberater = 'Herrn Steuerberater'
};

