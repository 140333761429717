import {Actions, createEffect, ofType} from '@ngrx/effects';
import {map, take} from 'rxjs';
import {Injectable} from '@angular/core';
import {BenutzerFormActions} from '../actions/benutzer-form.actions';
import {switchMap} from 'rxjs/operators';
import {MatDialog, MatDialogRef} from '@angular/material/dialog';


@Injectable()
export class BenutzerFormEffects {

  private formModal?: MatDialogRef<any>;

  constructor(
    private actions$: Actions,
    private dialog: MatDialog,
  ) {
  }

  /**
   * @property openModal$
   * @description
   * Dieser Effekt wird durch die `BenutzerFormActions.openModal` Aktion ausgelöst.
   * Er importiert dynamisch die `benutzerFormComponent` und öffnet ein Dialogfenster, das diese Komponente beinhaltet.
   * Sobald der Dialog wieder geschlossen wird, wird die Aktion `closedModal` ausgelöst.
   */
  readonly openModal$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerFormActions.openModal),
      switchMap(() => {
        return import('../../modules/dialogs/benutzer-form-dialog/benutzer-form-dialog.module')
          .then(m => m.BenutzerFormDialogModule.components.benutzerFormComponent);
      }),
      map(benutzerFormComponent => {
        this.formModal = this.dialog.open(
          benutzerFormComponent,
          {
            disableClose: true,
            maxWidth: '90vw',
            width: '1100px',
            autoFocus: false,
          }
        );
      }),
      switchMap(() => this.formModal!.afterClosed().pipe(take(1))),
      map(() => BenutzerFormActions.closedModal()),
    ),
  );


  /**
   * @property closeModal$
   * @description
   * Dieser Effekt wird durch die `BenutzerFormActions.closeModal` Aktion ausgelöst.
   * Er schließt das aktuell geöffnete Dialogfenster.
   */
  readonly closeModal$ = createEffect(
    () => this.actions$.pipe(
      ofType(BenutzerFormActions.closeModal),
      map(() => {
        this.formModal?.close();
      }),
    ), {dispatch: false}
  );

}
