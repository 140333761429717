/**
 * Benutzer
 * Eine API zum Anlegen, Aktualisieren, Anzeigen und Löschen von Benutzern in ADNOVA+ und im Portal. Bei internen Benutzern handelt es sich um Benutzer innerhalb einer Buchstelle oder Benutzer eines selbstbuchenden Landwirts/Unternehmens. Externe Benutzer sind  Benutzer eines Landwirts/Unternehmens, die von einer Buchstelle betreut werden. Hauptverantwortliche sind immer externe Benutzer, aber nicht jeder externe Benutzer ist Hauptverantwortlicher. Das Anlegen von externen Benutzern ist für interne Benutzer möglich. Nur ein externer Benutzer, der Hauptverantwortlicher ist, kann weitere externe Benutzer für die eigenen Betriebe anlegen. Interne Benutzer können externe Benutzer lesen und anlegen, sofern sie die entsprechenden Zugriffsrechte haben. Das Aktualisieren und Löschen von Benutzern kann hingegen nur von Hauptverantworåtlichen (externer Benutzer) in ihrer eigenen Organisation durchgeführt werden. 
 *
 * The version of the OpenAPI document: ${project.version}
 * 
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */
/* tslint:disable:no-unused-variable member-ordering */

import { Inject, Injectable, Optional }                      from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams,
         HttpResponse, HttpEvent, HttpParameterCodec }       from '@angular/common/http';
import { CustomHttpParameterCodec }                          from '../encoder';
import { Observable }                                        from 'rxjs';

import { BenutzerPortalDTO } from '../model/models';
import { BenutzerPortalUpdateDTO } from '../model/models';
import { BenutzerUploadResponseDTO } from '../model/models';

import { BASE_PATH, COLLECTION_FORMATS }                     from '../variables';
import { Configuration }                                     from '../configuration';



@Injectable({
  providedIn: 'root'
})
export class BenutzerPortalService {

    protected basePath = 'https://api.adnova.de/benutzer-service';
    public defaultHeaders = new HttpHeaders();
    public configuration = new Configuration();
    public encoder: HttpParameterCodec;

    constructor(protected httpClient: HttpClient, @Optional()@Inject(BASE_PATH) basePath: string, @Optional() configuration: Configuration) {
        if (configuration) {
            this.configuration = configuration;
        }
        if (typeof this.configuration.basePath !== 'string') {
            if (typeof basePath !== 'string') {
                basePath = this.basePath;
            }
            this.configuration.basePath = basePath;
        }
        this.encoder = this.configuration.encoder || new CustomHttpParameterCodec();
    }


    private addToHttpParams(httpParams: HttpParams, value: any, key?: string): HttpParams {
        if (typeof value === "object" && value instanceof Date === false) {
            httpParams = this.addToHttpParamsRecursive(httpParams, value);
        } else {
            httpParams = this.addToHttpParamsRecursive(httpParams, value, key);
        }
        return httpParams;
    }

    private addToHttpParamsRecursive(httpParams: HttpParams, value?: any, key?: string): HttpParams {
        if (value == null) {
            return httpParams;
        }

        if (typeof value === "object") {
            if (Array.isArray(value)) {
                (value as any[]).forEach( elem => httpParams = this.addToHttpParamsRecursive(httpParams, elem, key));
            } else if (value instanceof Date) {
                if (key != null) {
                    httpParams = httpParams.append(key,
                        (value as Date).toISOString().substr(0, 10));
                } else {
                   throw Error("key may not be null if value is Date");
                }
            } else {
                Object.keys(value).forEach( k => httpParams = this.addToHttpParamsRecursive(
                    httpParams, value[k], key != null ? `${key}.${k}` : k));
            }
        } else if (key != null) {
            httpParams = httpParams.append(key, value);
        } else {
            throw Error("key may not be null if value is not object or array");
        }
        return httpParams;
    }

    /**
     * Import von einem Benutzer
     * Importiert einen neuen externen Benutzer Dieser Aufruf ist nur für Hauptverantwortliche möglich 
     * @param benutzerPortalUpdateDTO Metadaten des zu importierenden Benutzers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public addBenutzer(benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BenutzerUploadResponseDTO>;
    public addBenutzer(benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BenutzerUploadResponseDTO>>;
    public addBenutzer(benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BenutzerUploadResponseDTO>>;
    public addBenutzer(benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (benutzerPortalUpdateDTO === null || benutzerPortalUpdateDTO === undefined) {
            throw new Error('Required parameter benutzerPortalUpdateDTO was null or undefined when calling addBenutzer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.post<BenutzerUploadResponseDTO>(`${this.configuration.basePath}/api/v1/benutzer/portal`,
            benutzerPortalUpdateDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Löscht einen Benutzer
     * Löscht den übergebenen Benutzer. Funktion steht nur Hauptverantwortlicher zur Verfügung. 
     * @param benutzerId Identifikation eines Benutzers.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public deleteBenutzer(benutzerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public deleteBenutzer(benutzerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public deleteBenutzer(benutzerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public deleteBenutzer(benutzerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (benutzerId === null || benutzerId === undefined) {
            throw new Error('Required parameter benutzerId was null or undefined when calling deleteBenutzer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.delete<any>(`${this.configuration.basePath}/api/v1/benutzer/portal/${encodeURIComponent(String(benutzerId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt einen Benutzer des eigenen Akteurs zurück
     * Gibt einen Benutzer des eigenen Akteurs zurück. Funktion steht nur Hauptverantwortlichen zur Verfügung. 
     * @param benutzerId Identifikation eines Benutzers.
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public getBenutzer(benutzerId: string, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<BenutzerPortalDTO>;
    public getBenutzer(benutzerId: string, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<BenutzerPortalDTO>>;
    public getBenutzer(benutzerId: string, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<BenutzerPortalDTO>>;
    public getBenutzer(benutzerId: string, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {
        if (benutzerId === null || benutzerId === undefined) {
            throw new Error('Required parameter benutzerId was null or undefined when calling getBenutzer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<BenutzerPortalDTO>(`${this.configuration.basePath}/api/v1/benutzer/portal/${encodeURIComponent(String(benutzerId))}`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Gibt alle Benutzer des eigenen Akteurs zurück
     * Gibt alle Benutzer des eigenen Akteurs zurück Funktion steht nur Hauptverantwortlichen zur Verfügung 
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public readBenutzer(observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<Array<BenutzerPortalDTO>>;
    public readBenutzer(observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpResponse<Array<BenutzerPortalDTO>>>;
    public readBenutzer(observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: 'application/json'}): Observable<HttpEvent<Array<BenutzerPortalDTO>>>;
    public readBenutzer(observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: 'application/json'}): Observable<any> {

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
                'application/json'
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.get<Array<BenutzerPortalDTO>>(`${this.configuration.basePath}/api/v1/benutzer/portal`,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

    /**
     * Aktualisiert einen Benutzer
     * Aktualisiert den übergebenen Benutzer. Funktion steht nur Hauptverantwortlichen zur Verfügung. 
     * @param benutzerId Identifikation eines Benutzers.
     * @param benutzerPortalUpdateDTO Metadaten des zu aktualisierenden Benutzers
     * @param observe set whether or not to return the data Observable as the body, response or events. defaults to returning the body.
     * @param reportProgress flag to report request and response progress.
     */
    public updateBenutzer(benutzerId: string, benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe?: 'body', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<any>;
    public updateBenutzer(benutzerId: string, benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe?: 'response', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpResponse<any>>;
    public updateBenutzer(benutzerId: string, benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe?: 'events', reportProgress?: boolean, options?: {httpHeaderAccept?: undefined}): Observable<HttpEvent<any>>;
    public updateBenutzer(benutzerId: string, benutzerPortalUpdateDTO: BenutzerPortalUpdateDTO, observe: any = 'body', reportProgress: boolean = false, options?: {httpHeaderAccept?: undefined}): Observable<any> {
        if (benutzerId === null || benutzerId === undefined) {
            throw new Error('Required parameter benutzerId was null or undefined when calling updateBenutzer.');
        }
        if (benutzerPortalUpdateDTO === null || benutzerPortalUpdateDTO === undefined) {
            throw new Error('Required parameter benutzerPortalUpdateDTO was null or undefined when calling updateBenutzer.');
        }

        let headers = this.defaultHeaders;

        let credential: string | undefined;
        // authentication (user-to-service-oauth) required
        credential = this.configuration.lookupCredential('user-to-service-oauth');
        if (credential) {
            headers = headers.set('Authorization', 'Bearer ' + credential);
        }

        let httpHeaderAcceptSelected: string | undefined = options && options.httpHeaderAccept;
        if (httpHeaderAcceptSelected === undefined) {
            // to determine the Accept header
            const httpHeaderAccepts: string[] = [
            ];
            httpHeaderAcceptSelected = this.configuration.selectHeaderAccept(httpHeaderAccepts);
        }
        if (httpHeaderAcceptSelected !== undefined) {
            headers = headers.set('Accept', httpHeaderAcceptSelected);
        }


        // to determine the Content-Type header
        const consumes: string[] = [
            'application/json'
        ];
        const httpContentTypeSelected: string | undefined = this.configuration.selectHeaderContentType(consumes);
        if (httpContentTypeSelected !== undefined) {
            headers = headers.set('Content-Type', httpContentTypeSelected);
        }

        let responseType: 'text' | 'json' = 'json';
        if(httpHeaderAcceptSelected && httpHeaderAcceptSelected.startsWith('text')) {
            responseType = 'text';
        }

        return this.httpClient.put<any>(`${this.configuration.basePath}/api/v1/benutzer/portal/${encodeURIComponent(String(benutzerId))}`,
            benutzerPortalUpdateDTO,
            {
                responseType: <any>responseType,
                withCredentials: this.configuration.withCredentials,
                headers: headers,
                observe: observe,
                reportProgress: reportProgress
            }
        );
    }

}
